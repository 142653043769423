import { MenuEntry } from 'jetswap-uikit-new'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://jetswap.finance',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://jetswap.finance/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://jetswap.finance/pools',
  },
  {
    label: 'Vaults',
    icon: 'VaultIcon',
    href: 'https://jetswap.finance/vaults',
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: 'https://jetswap.finance/lottery',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Overview',
        href: 'https://info.jetswap.finance/home',
        target: '_blank',
      },
      {
        label: 'Tokens',
        href: 'https://info.jetswap.finance/tokens',
        target: '_blank',
      },
      {
        label: 'Pairs',
        href: 'https://info.jetswap.finance/pairs',
        target: '_blank',
      },
      {
        label: 'Accounts',
        href: 'https://info.jetswap.finance/accounts',
        target: '_blank',
      },
    ],
  },
  // {
  //   label: 'IJO',
  //   icon: 'IfoIcon',
  //   href: 'https://jetswap.finance/ijo',
  // },
  // {
  //   label: 'Contest',
  //   icon: 'ContestIcon',
  //   items: [
  //     {
  //       label: 'Weekly Trading Contest',
  //       target: '_blank',
  //       href: 'https://jetswap.finance/trading',
  //     },
  //   ],
  // },
  {
    label: 'JETS',
    icon: 'JetsIcon',
    href: 'https://jetswap.finance/jets',
  },
  {
    label: 'Partnership',
    icon: 'PartnerIcon',
    href: 'https://forms.gle/gZ6EnfJtiooEr1mB7'
  },
  {
    label: 'Audit',
    icon: 'AuditIcon',
    href: 'https://docs.jetswap.finance/audits-and-security'
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.jetswap.finance',
        target: '_blank',
      },
      {
        label: 'Blog',
        href: 'https://jetfuelfinance.medium.com/',
        target: '_blank',
      },
      {
        label: 'Vote',
        href: 'https://vote.jetswap.finance/',
        target: '_blank',
      },
      {
        label: 'Jetfuel Finance',
        href: 'https://jetfuel.finance',
        target: '_blank',
      },
      // {
      //   label: 'Fortress',
      //   href: 'https://fortress.loans',
      //   target: '_blank',
      // },
      {
        label: 'Gforce',
        href: 'https://jetfuel.finance/gforce',
        target: '_blank',
      },
    ],
  },
]

export default config
